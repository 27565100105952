<template>
  <div>
    <div class="header-container">
      <div class="filter-color"></div>
      <div class="header-title">
      </div>
      <div class="header-title">
        <v-scale-transition>
          <div v-show="showText">
            Алексей + Татьяна
          </div>
        </v-scale-transition>
      </div>
      <div class="bottom-container d-flex justify-center">
        <v-scale-transition>
          <v-icon
              class="heart-icon"
              dark
              small
              @click="$vuetify.goTo(target, options)"
          >far fa-heart</v-icon>
        </v-scale-transition>
      </div>
    </div>
    <div ref="next-block">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderBlock',
    data() {
      return {
        showText: false,
        options: {
          duration: 1000,
          offset: -64,
          easing: 'easeInOutCubic',
        },
      }
    },
    computed: {
      target() {
        return this.$refs['next-block']
      }
    },
    mounted() {
      setTimeout(() => {
        this.showText = true;
      }, 500)
    },
  }
</script>

<style scoped>
  .header-container {
    position: relative;
    height: 100vh;
    min-height: 400px;
    background: url('../assets/rk-199.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .filter-color {
    width: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgba(255,214,161,0.50);
  }
  .header-title {
    font-weight: 300;
    position: absolute;
    font-size: 40px;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
  .bottom-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  .heart-icon {
    cursor: pointer;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    80% {
      transform: scale(1.5);
    }
    90% {
      opacity: 0.8;
      transform: scale(2);
    }
    100% {
      opacity: 0.2;
      transform: scale(2);
    }
  }
</style>
