<template>
  <div id="last-message" class="greetings-container d-flex flex-row flex-wrap align-center justify-center">
    <div class="col-12 col-md-6">
      <div class="message text-center px-4">
        Спасибо, что долистали до конца.
        <br>
        Но это не конец, это только начало!
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="mx-24 mt-12 d-flex justify-center">
        <div class="col-auto mx-auto">
          <v-img
              :src="require('../assets/last-block-img.png')"
              class="my-3 last-block-image mx-auto"
              max-width="560"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GreetingsBlock',
  }
</script>

<style scoped>
  .message {
    font-size: 30px;
    line-height: 1.55;
    font-weight: 700;
  }
</style>
