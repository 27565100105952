import Vue from 'vue';
import Router from 'vue-router';
import Layout from '../components/Layout'

Vue.use(Router);

export default new Router({
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            }
        }
    },
    routes: [
        {
            path: '*',
            name: 'Wedding',
            component: Layout,
        },
    ],
});
