<template>
  <div id="greetings" class="greetings-container d-flex flex-row flex-wrap">
    <div class="col-12 col-md-6">
      <div class="mx-24 mt-12 d-flex justify-center">
        <div class="col-auto">
          <v-img
              :src="require('../assets/rk-241.jpg')"
              class="my-3 greetings-image"
              max-width="560"
              max-height="560"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 pt-8">
      <v-card
          class="px-2 py-8"
          tile
          elevation="10"
      >
        <div class="greetings-title text-center">
          Привет!
        </div>
        <div class="line my-4"></div>
        <div class="message text-center px-4">
          Если ты тут, значит, ты оказался <b>избранным</b> человеком.<br>
          Возможно, ты с нами знаком с самого рождения, а, может, познакомились с тобой совсем недавно. Это не главное, а главное - помни:
          <br><br>
          — мы ценим тебя таким, какой ты есть,
          <br>
          — и ты очень для нас важен.
          <br><br>
          Приглашаем тебя прожить день вместе с нами. И мы хотим, чтобы наш день запомнился тебе <b>классным</b> днём с самыми <b>яркими</b>, <b>позитивными</b> эмоциями и событиями. Мы постараемся сделать этот день незабываемым и очень особенным.
          <br><br>
          А ты нам поможешь! Ниже инструкция :)
          <br><br>
          <div class="text-right"><i>Алексей и Татьяна</i></div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GreetingsBlock',
  }
</script>

<style scoped>
  .greetings-title {
    font-size: 42px;
    line-height: 1.23;
    font-weight: 600;
    color: #000;
  }
  .greetings-image {
    width: 100%;
  }
  .line {
    background-color: #ffb969;
    max-width: 50px;
    width: 100%;
    height: 3px;
    margin: 0 auto;
  }
  .message {
    font-size: 20px;
    line-height: 1.55;
    font-weight: 300;
  }
</style>
