<template>
  <div>
    <header class="header-container">
      <div class="header-bar d-flex align-center">
        <v-app-bar-nav-icon class="ml-4 d-md-none theme--dark" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <div class="align-center d-none d-md-flex">
          <v-btn
              color="white"
              href="/#wedding-day-plan"
              text
          >
            План свадебного дня
          </v-btn>
        </div>
        <div class="align-center d-none d-md-flex">
          <v-btn
              color="white"
              href="/#location"
              text
          >
            Локация
          </v-btn>
        </div>

        <div class="d-flex align-center logo-kings">
          <v-btn
              href="/"
              text
          >
            <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="./../assets/icon-kings.svg"
                transition="scale-transition"
                width="40"
            />
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <div class="align-center d-none d-md-flex">
          <v-btn
              color="white"
              href="/#what-to-gift"
              text
          >
            Что подарить?
          </v-btn>
        </div>

        <div class="align-center d-none d-md-flex">
          <v-btn
              color="white"
              href="/#what-to-wear"
              text
          >
            Что надеть?
          </v-btn>
        </div>
      </div>
    </header>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>
              <a
                class="link"
                href="/#wedding-day-plan"
              >
                План свадебного дня
              </a>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <a
                class="link"
                href="/#location"
              >Локация</a>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <a
                class="link"
                href="/#what-to-gift"
              >Что подарить?</a>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <a
                  class="link"
                  href="/#what-to-wear"
              >Что надеть?</a>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    name: 'BarComponent',
    data: () => ({
      drawer: false,
      group: null,
    }),
    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>

<style scoped>
.header-container {
  position: absolute;
  color: white;
  width: 100%;
  z-index: 1;
}
.header-bar {
  position: relative;
  height: 64px;
  margin-top: 0;
  transform: translateY(0px);
  left: 0;
  right: 0;
}
.logo-kings {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: table;
  height: inherit;
  z-index: 100;
}
.link {
  text-decoration: none;
  color: black;
}
</style>
