<template>
  <div id="help-block" class="greetings-container mb-10">
    <div class="plan-title text-center mt-6 pt-8">
      К кому обращаться по любым вопросам?
    </div>
    <div class="help-description text-center mb-8 pt-10 col-11 col-md-8 mx-auto">
      До свадьбы по любым вопросам можете писать нам в Telegram:
      <a href="https://t.me/mrlesha" target="_blank"><strong>Алексей</strong></a>
      и <a href="https://t.me/tanya_fomushka" target="_blank"><strong>Татьяна</strong></a>.
      <br><br>
      В день свадьбы эту помощь окажут
      <a href="https://t.me/ravil_sm" target="_blank"><strong>Равиль</strong></a>
      и <a href="https://t.me/Tink_13" target="_blank"><strong>Кристина</strong></a> добавьте себе
      их контакты, на всякий случай, или ищите среди гостей! :)
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PlanBlock',
  }
</script>

<style scoped>
  .plan-title {
    font-size: 36px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
  .help-description {
    font-size: 20px;
    letter-spacing: 0.5px;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    color: #000;
  }
</style>
