import { render, staticRenderFns } from "./PlanBlock.vue?vue&type=template&id=61c764a1&scoped=true&"
import script from "./PlanBlock.vue?vue&type=script&lang=js&"
export * from "./PlanBlock.vue?vue&type=script&lang=js&"
import style0 from "./PlanBlock.vue?vue&type=style&index=0&id=61c764a1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c764a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VAlert,VCard,VCardText,VCardTitle,VTimeline,VTimelineItem})
