<template>
  <div id="location" class="location-container pa-8">
    <div class="options-title text-center">
      Доступные опции на выбор
    </div>
    <div class="text-center pt-6">
      <em>Можно совместить несколько опций в одну:)</em>
    </div>
    <div class="d-flex justify-center align-center flex-wrap">
      <div class="col-auto">
        <v-card
            class="mx-auto my-6"
            max-width="300"
            tile
            elevation="10"
        >
          <v-img
              height="280"
              src="../assets/last-friend.png"
          ></v-img>

          <v-card-title>Бывший друг</v-card-title>
          <v-row
              align="center"
              class="ml-4"
          >
            <v-rating
                :value="2"
                color="amber"
                dense
                half-increments
                readonly
                size="10"
            ></v-rating>

            <div class="grey--text ms-4">
              2.0 (410)
            </div>
          </v-row>
          <v-card-text class="card-description mt-4">
            + Напился на велкоме
            <br>
            + Не плакал на церемонии
            <br>
            + Пришла в леопардовом платье
            <br>
            + Сказал "Счастья, здоровья" / Кричал "ГОРЬКО!"
            <br>
            + Подарил сервиз / постельное белье и пр.
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions class="d-flex justify-center">
            <v-btn
                color="deep-purple lighten-2"
                text
            >
              Выбрать
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="col-auto">
        <v-card
            class="mx-auto my-6"
            max-width="300"
            tile
            elevation="10"
        >
          <v-img
              height="280"
              src="../assets/friend.png"
          ></v-img>

          <v-card-title>Друг</v-card-title>
          <v-row
              align="center"
              class="ml-4"
          >
            <v-rating
                :value="4"
                color="amber"
                dense
                half-increments
                readonly
                size="10"
            ></v-rating>

            <div class="grey--text ms-4">
              4.0 (810)
            </div>
          </v-row>
          <v-card-text class="card-description mt-4">
            + Эффектно появился
            <br>
            + Немного пригубил для настроения на велкоме
            <br>
            + Пустил слезу на церемонии
            <br>
            + Учел дресс-код
            <br>
            + Рассказал прикольную историю во время тоста
            <br>
            + Подарил деньги
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions class="d-flex justify-center">
            <v-btn
                color="deep-purple lighten-2"
                text
            >
              Выбрать
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="col-auto">
        <v-card
            class="mx-auto my-6"
            max-width="300"
            tile
            elevation="10"
        >
          <v-img
              height="280"
              src="../assets/best-friend.png"
          ></v-img>

          <v-card-title>Лучший друг</v-card-title>
          <v-row
              align="center"
              class="ml-4"
          >
            <v-rating
                :value="5"
                color="amber"
                dense
                half-increments
                readonly
                size="10"
            ></v-rating>

            <div class="grey--text ms-4">
              5.0+ (910)
            </div>
          </v-row>
          <v-card-text class="card-description mt-4">
            Все пункты опции "Друг"
            <br>
            + Взорвал танцпол и повеселился от души
            <br>
            + Подготовил творческий сюрприз
            <br>
            + Выкупил торт
            <br>
            + Подарил бутылку вина вместо цветов
            <br>
            + Остался переночевать
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions class="d-flex justify-center">
            <v-btn
                color="deep-purple lighten-2"
                text
            >
              Выбрать
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LocationBlock',
  }
</script>

<style scoped>
  .options-title {
    font-size: 36px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
  .card-description {
    min-height: 208px;
  }
</style>
