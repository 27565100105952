<template>
  <div :id="id" class="location-container pa-8">
    <div class="title-block text-center">
      {{ title || '' }}
    </div>
    <div class="description-block pt-6 col-12 col-md-8 mx-auto text-center">
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DefaultBlock',
    props: ['title', 'id']
  }
</script>

<style scoped>
  .title-block {
    font-size: 36px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
  .description-block {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
  }
</style>
