<template>
  <div id="wedding-day-plan" class="greetings-container">
    <div class="plan-title text-center mt-6 pt-8">
      План свадебного дня
    </div>
    <div class="date text-center mb-8">
      <em>26 августа 2021 года, четверг</em>
    </div>
    <div class="timeline-block d-flex flex-column align-center">
      <div class="col-md-10 col-lg-8 col-12 px-2">
        <v-timeline>
          <v-timeline-item color="teal light-3">
            <span slot="opposite"><b>10:30</b></span>
            <v-card class="elevation-2">
              <v-card-title class="text-h6 text-md-h5 text-break pa-1 pa-md-4">
                Свадебная церемония
              </v-card-title>
              <v-card-text class="px-1 px-md-4 pb-2 pb-md-4">
                В ЗАГСе смотрим роспись! ЗАГС располагается по адресу: <a href="https://goo.gl/maps/YQcGjGrua5b2HkcV7" target="_blank">г. Петергоф, Торговая пл., 5</a>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="#ffb969" small>
            <span slot="opposite"><b>15:30</b></span>
            <v-card class="elevation-2">
              <v-card-title class="text-h6 text-md-h5 text-break pa-1 pa-md-4">
                Сбор гостей, фуршет
              </v-card-title>
              <v-card-text class="px-1 px-md-4 pb-2 pb-md-4">
                Начало свадебного вечера в <a href="https://g.page/GardenLoft1?share" target="_blank">Garden Loft</a>.
                <br>Фотобудка и другие прелести!
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="teal light-3">
            <span slot="opposite"><b>17:00</b></span>
            <v-card class="elevation-2">
              <v-card-title class="text-h6 text-md-h5 text-break pa-1 pa-md-4">
                Ужин, яркие танцы и песни
              </v-card-title>
              <v-card-text class="px-1 px-md-4 pb-2 pb-md-4">
                Пора хорошо веселиться, этот день наш!
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="#ffb969" small>
            <span slot="opposite"><b>20:00</b></span>
            <v-card class="elevation-2">
              <v-card-title class="text-h6 text-md-h5 text-break pa-1 pa-md-4">
                Кушаем вкуснейший тортик
              </v-card-title>
              <v-card-text class="px-1 px-md-4 pb-2 pb-md-4">
                Урви главный кусок самого вкусного торта в <b>Санкт-Петербурге</b>!
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="teal light-3">
            <span slot="opposite"><b>21:00</b></span>
            <v-card class="elevation-2">
              <v-card-title class="text-h6 text-md-h5 text-break pa-1 pa-md-4">
                Двигаемся под музыку диджея
              </v-card-title>
              <v-card-text class="px-1 px-md-4 pb-2 pb-md-4">
                Если ещё есть силы, пора выкладываться на полную...
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="#ffb969" small>
            <span slot="opposite"><b>22:00</b></span>
            <v-card class="elevation-2">
              <v-card-title class="text-h6 text-md-h5 text-break pa-1 pa-md-4">
                Счастливые едем домой
              </v-card-title>
              <v-card-text class="px-1 px-md-4 pb-2 pb-md-4">
                или нет ;)
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <div class="pt-16 pb-8">
          <v-alert
              text
              dense
              color="teal"
              icon="mdi-clock-fast"
              border="left"
          >
            <div class="font-weight-bold">
              Объявление 1
            </div>
            Все гости могут остаться на ночь в комплексе переночевать, соседи будут только те люди, которые будут гулять на свадьбе, утром доедим то, что останется с вечера! :)
          </v-alert>
        </div>
        <div class="pb-16">
          <v-alert
              text
              dense
              color="deep-orange"
              icon="mdi-clock-fast"
              border="left"
          >
            <div class="font-weight-bold">
              Объявление 2
            </div>
            Трансфер будет заказан ко времени регистрации, но мы понимаем, что у нас очень большой перерыв
            между посещением ЗАГСа и началом всего мероприятия. Соответственно будет много свободного времени (4 часа),
            поэтому Вы можете приехать к началу фуршета в <b>Garden Loft</b> или прогуляться и посмотреть фонтаны <b>Петергофа</b>,
            которые находятся в 5-ти минутах хотьбы от назначенного места. <b>Предупредите, пожалуйста, нас о своих планах!</b>
          </v-alert>
        </div>
      </div>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PlanBlock',
  }
</script>

<style scoped>
  .plan-title {
    font-size: 36px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
  .date {
    font-size: 20px;
    line-height: 1.55;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    color: #000;
  }
  .timeline-block {
    width: 100%;
  }
</style>
