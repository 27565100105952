<template>
  <div id="team" class="location-container pa-8">
    <div class="team-title text-center">
      Команда
    </div>
    <div class="text-center pt-6">
      <em>Люди, которые вкладывают <b>всю</b> свою душу, чтобы нам было <b>весело</b> и <b>комфортно</b>, и, чтобы мы всё запомнили!</em>
    </div>
    <div class="d-flex justify-center align-center flex-wrap pt-10 col-11 col-md-8 mx-auto">
      <div class="col-auto d-flex flex-column align-center pb-10 px-10">
        <v-avatar
          size="150"
        >
          <img
            src="../assets/headliner.png"
            alt="Александр Муштоватый"
          >
        </v-avatar>
        <div class="member-name name text-center pt-4">
          Александр Муштоватый
        </div>
        <div class="text-center caption">
          ведущий
        </div>
      </div>
      <div class="col-auto d-flex flex-column align-center pb-10 px-10">
        <v-avatar
          size="150"
        >
          <img
            src="../assets/photographer.png"
            alt="Роман Корольков"
          >
        </v-avatar>
        <div class="member-name name text-center pt-4">
          Роман Корольков
        </div>
        <div class="text-center caption">
          фотограф
        </div>
      </div>
      <div class="col-auto d-flex flex-column align-center pb-10 px-10">
        <v-avatar
          size="150"
        >
          <img
            src="../assets/videographer.png"
            alt="Руслан Зиганшин"
          >
        </v-avatar>
        <div class="member-name name text-center pt-4">
          Руслан Зиганшин
        </div>
        <div class="text-center caption">
          видеограф
        </div>
      </div>
      <div class="col-auto d-flex flex-column align-center pb-10 px-10">
        <v-avatar
          size="150"
        >
          <img
            src="../assets/dj.png"
            alt="Руслан Зиганшин"
          >
        </v-avatar>
        <div class="member-name name text-center pt-4">
          Андрей Захаров
        </div>
        <div class="text-center caption">
          диджей
        </div>
      </div>
      <div class="col-auto d-flex flex-column align-center pb-10 px-10">
        <v-avatar
          size="150"
        >
          <img
            src="../assets/florist.png"
            alt="Елена Семёнова"
          >
        </v-avatar>
        <div class="member-name name text-center pt-4">
          Елена Семёнова
        </div>
        <div class="text-center caption">
          флорист
        </div>
      </div>
      <div class="col-auto d-flex flex-column align-center pb-10 px-10">
        <v-avatar
          size="150"
        >
          <img
            src="../assets/kristina.png"
            alt="Кристина Ерохина"
          >
        </v-avatar>
        <div class="member-name name text-center pt-4">
          Кристина Ерохина
        </div>
        <div class="text-center caption">
          фотограф
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LocationBlock',
  }
</script>

<style scoped>
  .team-title {
    font-size: 36px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
  .member-name {
    font-size: 22px;
    line-height: 1.35;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
</style>
