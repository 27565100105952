<template>
  <div id="location" class="location-container pa-8">
    <div class="location-title text-center">
      Где это место?
    </div>
    <div class="text-center pt-6">
      <em>Наша свадьба будет в очень уютном месте рядом с Санкт-Петербургом, в городе Петергоф (был одной из главных летних императорских резиденций).</em>
    </div>
    <div class="d-flex justify-center align-center flex-wrap">
      <div class="col-auto">
        <v-card
            class="mx-auto my-12"
            max-width="450"
            tile
            elevation="10"
        >
          <v-img
              height="250"
              src="../assets/garden-loft.jpeg"
          ></v-img>

          <v-card-title><a href="https://g.page/GardenLoft1?share" target="_blank">Garden Loft</a></v-card-title>

          <v-card-text class="location-description">
            <div class="mb-4 text-subtitle-1">
              <b>Адрес</b>: Санкт-Петербург, г. Петергоф, Золотая улица, д. 2
            </div>

            <div>Это комплекс, расположенный в самом центре Петергофа, рядом с Фонтанами Петродворца!</div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions class="d-flex justify-center">
            <v-btn
                color="deep-purple lighten-2"
                text
            >
              Хочу уже туда!
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <div class="col-auto">
        <v-card
          class="mx-auto my-12"
          max-width="450"
          tile
          elevation="10"
      >
        <v-img
            height="250"
            src="../assets/bus.png"
        ></v-img>

        <v-card-title>Трансфер</v-card-title>

        <v-card-text class="location-description">
          <div>
            И да, мы запарились и сделали трансфер для всех гостей от <a href="https://goo.gl/maps/3AgA3qS1qAe16ktK6" target="_blank">метро Автово</a>,
            время отпишем вам подробнее!
            <br>
            <br>
            Ищите автобус как на фото :)
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="d-flex justify-center">
          <v-btn
              color="deep-purple lighten-2"
              text
          >
            Поехали!
          </v-btn>
        </v-card-actions>
      </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LocationBlock',
  }
</script>

<style scoped>
  .location-title {
    font-size: 36px;
    line-height: 1.23;
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 600;
  }
  .location-description {
    min-height: 132px;
  }
</style>
