<template>
  <v-app>
    <BarComponent/>
    <v-main style="padding: 0">
      <HeaderBlock/>
      <GreetingsBlock/>
      <PlanBlock/>
      <LocationBlock/>
      <DefaultBlock
          id="what-to-wear"
          title="Что надеть?"
      >
        <template v-slot:message>
          <div>
            Строго мы вас не ограничиваем, но пару пожеланий озвучим.
            <br><br>
            Для джентельменов будут уместными классические костюмы с <b>белой</b> рубашкой.
            <br>
            Мы будем рады, если девушки выберут однотонные вечерние или коктейльные платья спокойных оттенков и без принтов.
            <br><br>
            Обязательно предусмотрите <b>удобную</b> обувь для танцев и <b>тёплую</b> одежду для <b>вечера</b>.
          </div>
          <v-img class="mt-8" src="../assets/clothes.png"></v-img>
        </template>
      </DefaultBlock>
      <DefaultBlock
          id="what-to-gift"
          title="Что подарить?"
      >
        <template v-slot:message>
          <div>
            Не ломайте голову над подарками! Ваши конверты помогут нам осуществить мечту :)
            <br><br>
            Все знают о традиции дарения цветов на росписи в ЗАГСе! К сожалению,
            <br>
            у нас не будет возможности насладиться ароматом и красотой цветов, но мы с удовольствием насладимся <b>ароматом вина</b>, подаренного вами и будем открывать по одной бутылке на каждую нашу последующую годовщину.
            <br><br>
            Если вы подготовили для нас сюрприз или творческий номер, пожалуйста, не забудьте предупредить о нем нашего ведущего
            <a href="https://www.instagram.com/al.musht/" target="_blank">Александра</a>.
          </div>
        </template>
      </DefaultBlock>
      <OptionsBlock/>
      <TeamBlock/>
      <HelpBlock/>
      <LastBlock/>
    </v-main>
  </v-app>
</template>

<script>

import BarComponent from './BarComponent';
import GreetingsBlock from './GreetingsBlock';
import HeaderBlock from './HeaderBlock';
import PlanBlock from './PlanBlock';
import LocationBlock from './LocationBlock';
import DefaultBlock from './DefaultBlock';
import OptionsBlock from './OptionsBlock';
import TeamBlock from './TeamBlock';
import HelpBlock from './HelpBlock';
import LastBlock from './LastBlock';

export default {
  name: 'Layout',

  components: {
    BarComponent,
    GreetingsBlock,
    HeaderBlock,
    LocationBlock,
    PlanBlock,
    DefaultBlock,
    OptionsBlock,
    TeamBlock,
    HelpBlock,
    LastBlock,
  },

  data: () => ({
    //
  }),
};
</script>
